import React from 'react'
import { useRef } from 'react'
import ProductSourcingCarousel from './ProductSourcingCarousel';

const ProcurementSolutions = () => {
    const pssection1 = useRef (null);

    const slides = [
        { src: "assets/imgs/bg/bg2.jpg", alt: "Background 2" },
        { src: "assets/imgs/bg/bg3.jpg", alt: "Background 3" },
        { src: "assets/imgs/bg/bg4.jpg", alt: "Background 4" },
        { src: "assets/imgs/bg/bg5.jpg", alt: "Background 5" },
        { src: "assets/imgs/bg/bg6.jpg", alt: "Background 6" },
        { src: "assets/imgs/bg/bg7.jpg", alt: "Background 7" },
        { src: "assets/imgs/bg/bg8.jpg", alt: "Background 8" },
        { src: "assets/imgs/bg/bg9.jpg", alt: "Background 9" },
    ];

return (
    <>
        <section className="hero-3 position-relative align-items">
            <h2 className="mb-30 text-center">Procurement Solutions</h2>
            
        </section>
        <div className="page-content pt-50">
            <div className="container">
                <div className="row">
                    <div className="col-xl-10 col-lg-12 m-auto">
                        <section className="row align-items-center mb-50" ref={pssection1}>
                            <div className="col-lg-6">
                                <img src="assets/imgs/banner/about-1.jpg" alt="" className="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4" />
                            </div>
                            <div className="col-lg-6">
                                <div className="pl-25">
                                    <h2 className="mb-30">Information gathering and product sourcing</h2>
                                    <p className="mb-25">Procure Log India plays a critical role in boosting international trade by connecting exporters and buyers. We provide vital support to importers as they navigate the complexity of product sourcing and information collection. Using our broad networks and industry experience, Procure Log India can link importers with trustworthy suppliers throughout the world, assuring access to high-quality items at affordable pricing. Furthermore, they specialize at obtaining critical market knowledge, such as regulatory requirements, tariff structures, and developing trends, allowing importers to make more educated judgments. Procure Log India serves as trusted partners by providing individualized advice and bespoke solutions, allowing importers to maximize their sourcing strategies and accomplish their business objectives in the global marketplace.</p>
                                    <div>
                                        <ProductSourcingCarousel id="carousel-1" slides={slides} />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="text-center mb-50">
                            <h2 className="title style-3 mb-20">Obtaining Competitive Quotation</h2>
                            <p className="mb-20">Procure Log India uses their broad network and skills to obtain competitive quotes from a variety of providers. Here's how they generally do it</p>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 mb-24">
                                    <div className="featured-card">
                                        <img src="assets/imgs/theme/icons/icon-2.svg" alt="" />
                                        <h4>Network Building</h4>
                                        <p>Procure Log India cultivates partnerships with a diverse set of suppliers from various locations and sectors. These networks frequently comprise manufacturers, wholesalers, distributors, and agents.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-24">
                                    <div className="featured-card">
                                        <img src="assets/imgs/theme/icons/icon-4.svg" alt="" />
                                        <h4>Market Knowledge</h4>
                                        <p>They remain current on market developments, price, and supplier capabilities. This data enables businesses to discover suppliers that can provide competitive cost without sacrificing quality.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-24">
                                    <div className="featured-card">
                                        <img src="assets/imgs/theme/icons/icon-3.svg" alt="" />
                                        <h4>Negotiation Skills</h4>
                                        <p>Procure Log India employs skilled negotiators who can argue for importers to obtain advantageous terms and price. They grasp the complexities of international trade agreements and apply their knowledge to reduce costs.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-24">
                                    <div className="featured-card">
                                        <img src="assets/imgs/theme/icons/icon-1.svg" alt="" />
                                        <h4>Volume Discounts</h4>
                                        <p>By consolidating orders from multiple importers or negotiating bulk purchases, Procure Log India can often secure volume discounts from suppliers. This enables importers to access lower unit prices and improve their competitiveness in the market.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-24">
                                    <div className="featured-card">
                                        <img src="assets/imgs/theme/icons/icon-5.svg" alt="" />
                                        <h4>Transparency and Fairness</h4>
                                        <p>Procure Log India makes transparency and fairness a priority throughout the quote process. They give importers with complete information on price structures, terms of sale, and any additional fees or levies, allowing them to make educated judgments.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-24">
                                    <div className="featured-card">
                                        <img src="assets/imgs/theme/icons/icon-6.svg" alt="" />
                                        <h4>Competitive Quote </h4>
                                        <p>Procure Log India plays an important role in bringing competitive quotations from different suppliers to importers, ultimately assisting importers in optimizing their sourcing strategies and achieving their business objectives.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="row align-items-center mb-50">
                            <div className="row mb-50 align-items-center">
                                <div className="col-lg-7 pr-30">
                                    <img src="assets/imgs/page/about-5.png" alt="" className="mb-md-3 mb-lg-0 mb-sm-4" />
                                </div>
                                <div className="col-lg-5">
                                    <h4 className="heading-1 mb-10">Conduct factory verification and supplier evaluation</h4>
                                    <p className="mb-30">Procure Log India rigorously evaluates suppliers on the basis of dependability, quality, delivery timelines, and response. They guarantee that importers obtain quotes from credible suppliers that can satisfy their criteria.</p>
                                    <h4 className="mb-10">Sample arrangements</h4>
                                    <p>We arrange samples before placing the final purchase after the importer has agreed on the product and provider. <br/>Samples can be transported commercially or non-commercially using a channel courier partner like DHL or FedEx.
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 text-center pr-30 mb-md-5 mb-lg-0 mb-sm-5">
                                    <h2 className="title style-3 mb-20">Quality Control and Inspection Report</h2>
                                    <p className="mb-20">Procure Log India is responsible for preparing quality control and inspection reports to guarantee that imported items satisfy the specified standards and specifications. Here's how we generally handle this process:</p>
                                </div>
                                <div className="col-lg-4 mb-24 d-flex">
                                    <div className="featured-card bg1">
                                        <p>Procure Log India coordinates with inspection agencies and third-party quality control organizations for pre-shipment inspections. These inspections take place at the supplier's facilities before the items are sent to ensure their quality, quantity, and conformity with requirements.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-24 d-flex">
                                    <div className="featured-card bg2">
                                        <p>Prior to inspection, Procure Log India collaborates with importers to develop quality requirements and standards for goods. This may include size, materials, functionality, and safety standards.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-24 d-flex">
                                    <div className="featured-card bg3">
                                        <p>Procure Log India coordinates inspection schedules with suppliers to fit with production timelines and shipment arrangements. They explain the inspection criteria properly to the supplier to ensure a seamless procedure.</p>
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-24 d-flex">
                                    <div className="featured-card bg4">
                                        <p>Procure Log India may send a representative to monitor on-site inspections at the supplier's facilities. They guarantee that the inspection follows the agreed-upon standards and that any anomalies or concerns are swiftly resolved.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-24 d-flex">
                                    <div className="featured-card bg5">
                                        <p>Procure Log India will evaluate the inspection reports and accompanying documents given by the inspection agency. They ensure that the items satisfy the quality specifications and that all pertinent information is properly documented.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-24 d-flex">
                                    <div className="featured-card bg6">
                                        <p>Procure Log India maintains open contact with importers during the inspection process, providing frequent updates on progress and results. They answer any concerns or questions presented by the importer and work together to remedy any issues discovered during the inspection.</p>
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-24 d-flex">
                                    <div className="featured-card bg7">
                                        <p>Procure Log India swiftly submits inspection reports to the importer for assessment. They facilitate any necessary follow-up steps, such as re-inspections or remedial procedures, to resolve any nonconformities or shortcomings that have been detected.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-24 d-flex">
                                    <div className="featured-card bg8">
                                        <p>Procure Log India successfully manages quality control and inspection processes to assist importers reduce risks, assure product quality and compliance, and maintain their reputation in the market. Their experience and attention to detail are critical in protecting importers' interests and facilitating successful trade agreements.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-24 d-flex">
                                    <div className="featured-card bg9">
                                        <p>Arranging and placing final orders. Once the importer has accepted the sample, we place the final order with the shipper on their behalf. Purchase orders are communicated with shippers once they have been confirmed.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ProcurementSolutions
