import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductSourcingCarousel = ({ id, slides }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        arrows: true,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        prevArrow: (
            <span className="slider-btn slider-prev">
                <i className="fi-rs-arrow-small-left"></i>
            </span>
        ),
        nextArrow: (
            <span className="slider-btn slider-next">
                <i className="fi-rs-arrow-small-right"></i>
            </span>
        ),
        appendArrows: `#${id}-arrows`,
    };

    return (
        <div className="carausel-3-columns-cover position-relative">
            <div id={`${id}-arrows`} className="slider-arrows"></div>
            <Slider {...settings} className="carausel-3-columns">
                {slides.map((slide, index) => (
                    <div key={index}>
                        <img src={slide.src} alt={slide.alt || `Slide ${index + 1}`} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ProductSourcingCarousel;